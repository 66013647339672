.footer {
  background-color: $color-blue;
  color: $color-white;
  padding-bottom: 65px;

  .logo {
    margin-top: 45px;
    margin-bottom: 15px;
  }

  h4 {
    text-align: left;
  }

  .address,
  .about,
  .location {
    border-left: 1px solid $color-white;
    padding: 10px 40px 10px 20px;
    margin: 10px 0;

    a {
      text-decoration: none;
    }

    p:nth-child(1) {
      margin-top: 0;
    }

    p:nth-last-child(1) {
      margin-bottom: 0;
    }
  }

  .about {
    @include breakpoint('medium') {
      padding-bottom: 25px;
    }
  }

  .address {
    @include breakpoint('medium') {
      border-left: none;
    }

    .hide-sm {
      @include breakpoint-down('medium') {
        display: none;
      }
    }
  }
}
