/* stylelint-disable plugin/stylelint-bem-namics */

$columns-count: 12;

:root {
  --container-padding: 24px;
  --content-width: calc(1160px + (2 * var(--container-padding)));
  --grid-column-gap: 16px;
  --grid-row-gap: 16px;

  @include breakpoint('medium') {
    --container-padding: 40px;
    --grid-column-gap: 32px;
    --grid-row-gap: 56px;
  }

  @include breakpoint('large') {
    --grid-column-gap: 56px;
  }
}

.container {
  padding-left: var(--container-padding);
  padding-right: var(--container-padding);
  width: 100%;
  margin: 0 auto;

  &:not(.container--fluid) {
    max-width: var(--content-width);
  }
}

.grid {
  display: grid;
  grid-template-columns: repeat($columns-count, 1fr);
  grid-gap: var(--grid-row-gap) var(--grid-column-gap);

  &.no-gap {
    grid-gap: 0 0 !important;
  }

  &.no-row-gap {
    grid-row-gap: 0 !important;
  }
}

@for $i from 1 through $columns-count {
  .order-#{$i} {
    order: #{$i};
  }

  .col-#{$i} {
    grid-column-end: span #{$i};
  }

  .offset-#{$i} {
    grid-column-start: #{$i + 1};
  }
}

@include breakpoint('small') {
  @for $i from 0 through $columns-count {
    .order-sm-#{$i} {
      order: #{$i};
    }

    .col-sm-#{$i} {
      grid-column-end: span #{$i};
    }

    .offset-sm-#{$i} {
      grid-column-start: #{$i+ 1};
    }
  }
}

@include breakpoint('medium') {
  @for $i from 0 through $columns-count {
    .order-md-#{$i} {
      order: #{$i};
    }

    .col-md-#{$i} {
      grid-column-end: span #{$i};
    }

    .offset-md-#{$i} {
      grid-column-start: #{$i+ 1};
    }
  }
}

@include breakpoint('large') {
  @for $i from 0 through $columns-count {
    .order-lg-#{$i} {
      order: #{$i};
    }

    .col-lg-#{$i} {
      grid-column-end: span #{$i};
    }

    .offset-lg-#{$i} {
      grid-column-start: #{$i + 1};
    }
  }
}

@media print {
  @for $i from 1 through $columns-count {
    .order-print-#{$i} {
      order: #{$i};
    }

    .col-print-#{$i} {
      grid-column-end: span #{$i};
    }

    .offset-print-#{$i} {
      grid-column-start: calc(#{$i} + 1);
    }
  }
}
