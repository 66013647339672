$icon-font-family: 'icomoon';

@font-face {
  font-family: 'icomoon';
  src: url('fonts/icomoon.eot?lpg2za');
  src:
    url('fonts/icomoon.eot?lpg2za#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?lpg2za') format('truetype'),
    url('fonts/icomoon.woff?lpg2za') format('woff'),
    url('fonts/icomoon.svg?lpg2za#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: $icon-font-family !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-arrow-down::before {
  content: "\e900";
  color: #fff;
}

.icon-arrow-next::before {
  content: "\e901";
  color: #fff;
}

.icon-arrow-prev::before {
  content: "\e902";
}
