.is-hidden {
  display: none;
}

.is-hidden-md-down {
  @include breakpoint-down('medium') {
    display: none;
  }
}

.no-margin {
  margin: 0 !important;
}

.no-margin-top {
  margin-top: 0 !important;
}

.no-margin-bottom {
  margin-bottom: 0 !important;
}

.no-border {
  border: none !important;
}
