.about-us {
  z-index: 1;
  position: relative;

  .element-04 {
    position: absolute;
    z-index: -1;
    left: -154px;
    top: 300px;
    transform: scale(0.5);

    @include breakpoint('large') {
      left: -197px;
      top: 400px;
      transform: scale(1);
    }

    @include breakpoint('xlarge') {
      left: 240px;
    }
  }

  .portrait {
    position: relative;
    z-index: -1;
  }

  h2 {
    margin-bottom: 35px;
    font-size: clamp(32px, 6vw, 64px);

    @include breakpoint('medium') {
      margin-bottom: 80px;
    }
  }

  .caption {
    font-family: var(--plex);
    text-align: center;
    font-size: 32px;
    padding-top: 40px;
    color: $color-blue;
    letter-spacing: 2.5px;

    @include breakpoint-down('medium') {
      display: none;
    }
  }

  p:nth-child(1) {
    margin-top: 0;
  }

  .offset-md-0 {
    @include breakpoint('medium') {
      grid-column-start: 1;
    }
  }
}
