table {
  background-color: $color-white;

  th {
    background-color: $color-blue;
    color: $color-white;
    padding: 32px 0;
    font-size: 24px;
    font-family: var(--plex);
    line-height: 1;
    letter-spacing: 4.5px;
    font-weight: 400;

    @include breakpoint('medium') {
      padding: 55px 0 45px 0;
      font-size: 32px;
    }
  }

  td {
    vertical-align: top;
  }

  ul {
    list-style-type: none;
    margin: 32px 20px 70px;
    padding-left: 0;

    @include breakpoint('medium') {
      margin: 48px 28px 170px 34px;
    }

    li {
      padding-bottom: 30px;
    }
  }
}

.border-right {
  border-right: 1px solid $color-blue;
}
