.main-menu {
  display: flex;
  align-items: center;
  font-family: var(--plex);

  &__button {
    padding: 10px;
    transition: all 0.2s ease;
    cursor: pointer;
    z-index: 3;

    &.is-open {
      z-index: 1001;
      padding-bottom: 10px;
    }
  }

  &__items {
    position: absolute;
    background: $color-blue;
    right: -100%;
    top: -27px;
    overflow-y: scroll;
    list-style: none;
    padding: 15px;
    transition: all 0.2s ease;
    scrollbar-width: none;
    z-index: 1000;
    border-top: 70px solid $color-beige;
    border-bottom: 33px solid $color-beige;
    border-left: 20px solid $color-beige;
    border-right: 20px solid $color-beige;
    margin-top: 27px;
    letter-spacing: 6px;

    @include breakpoint('medium') {
      border-top: 50px solid $color-beige;
      border-bottom: 40px solid $color-beige;
      border-left: 40px solid $color-beige;
      border-right: 40px solid $color-beige;
    }

    &::-webkit-scrollbar {
      display: none;
    }

    &.is-open {
      right: 0;
      width: 100vw;
      height: 100vh;
    }

    img {
      padding: 10px 0 80px;

      @include breakpoint('medium') {
        padding-bottom: 50px;
      }

      @include breakpoint-down('xsmall') {
        padding-bottom: 40px;
      }
    }

    a {
      color: $color-white;
      text-decoration: none;
      padding: 8px 0;
      font-size: 24px;
      line-height: 40px;
      display: inline-block;
      border-bottom: 1px solid $color-blue;
      transition: border-color 0.3s ease;

      @include breakpoint('medium') {
        font-size: 45px;
        line-height: 28px;
        padding: 16px 0;
      }

      @include breakpoint('large') {
        font-size: 50px;
        line-height: 34px;
        padding: 15px 0;
      }

      @include breakpoint('xlarge') {
        font-size: 80px;
        line-height: 49px;
        padding: 20px 0;
      }

      @include breakpoint('xxlarge') {
        font-size: 100px;
        line-height: 62px;
        padding: 35px 0;
      }

      &:hover,
      &.active {
        border-color: $color-white;
      }
    }
  }
}

.menu-icon {
  position: absolute;
  top: 10px;
  left: 50%;
  z-index: 1;
  transform: translateX(-50%);

  &__bar {
    background-color: $color-white;
    width: 30px;
    height: 2px;
    margin-bottom: 6px;
    display: block;
    transition: transform 250ms ease, background 250ms ease;

    &:last-child {
      margin-bottom: 0;
    }

    .is-open & {
      background-color: $color-blue;

      &:first-child {
        transform: translateY(8px) rotate(225deg);
      }

      &:nth-child(2) {
        transform: scale(0);
      }

      &:last-child {
        transform: translateY(-8px) rotate(315deg);
      }
    }
  }
}
