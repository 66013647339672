$breakpoints: (
  'xsmall': 400px,
  'small': 767px,
  'medium': 992px,
  'large': 1200px,
  'xlarge': 1520px,
  'xxlarge': 1800px
);

@mixin breakpoint($breakpoint) {
  $value: map-get($breakpoints, $breakpoint);

  @if $value != null {
    @media (min-width: $value) {
      @content;
    }
  }

  @else {
    @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
      + "Please make sure it is defined in `$breakpoints` map.";
  }
}

@mixin breakpoint-up($breakpoint) {
  $value: map-get($breakpoints, $breakpoint);

  @if $value != null {
    @media (min-width: $value) {
      @content;
    }
  }

  @else {
    @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
      + "Please make sure it is defined in `$breakpoints` map.";
  }
}

@mixin breakpoint-down($breakpoint) {
  $value: map-get($breakpoints, $breakpoint);

  @if $value != null {
    @media (max-width: $value) {
      @content;
    }
  }

  @else {
    @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
      + "Please make sure it is defined in `$breakpoints` map.";
  }
}

.hide-sm-up {
  @include breakpoint('small') {
    display: none;
  }
}

.hide-md-up {
  @include breakpoint('medium') {
    display: none;
  }
}
