@import "node_modules/swiper/swiper.scss";
@import "node_modules/swiper/components/pagination/pagination.scss";
@import "node_modules/swiper/components/navigation/navigation.scss";

@import
  'modern-normalize',
  'breakpoints',
  'grid',
  'colors',
  'typography',
  'base',
  'helpers',
  'header',
  'main-menu',
  'footer',
  'cookies',
  'button',
  'newsletter',
  'event',
  'about-us',
  'weekly-menu',
  'ala-carte',
  'reservations',
  'table',
  'slider',
  'icomoon';
