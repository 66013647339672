.ala-carte {
  overflow: visible;
  z-index: 2;
  position: relative;

  @include breakpoint('medium') {
    padding-bottom: 80px;
  }

  .element-06 {
    position: absolute;
    left: -30%;
    top: -8%;
    z-index: -1;
    transform: scale(0.5);

    @include breakpoint('medium') {
      left: -10%;
      top: -5%;
      transform: scale(0.7);
    }

    @include breakpoint('large') {
      left: 0;
      top: -5%;
      transform: scale(0.7);
    }

    @include breakpoint('xlarge') {
      left: 10%;
    }
  }

  img {
    position: absolute;
    transform: scale(0.4);
    z-index: -1;

    @include breakpoint('large') {
      transform: scale(0.8);
    }

    @include breakpoint('xlarge') {
      transform: scale(0.9);
    }
  }

  .hide-sm {
    @include breakpoint-down('small') {
      display: none;
    }
  }

  table {
    position: relative;
    z-index: -1;
    background-color: $color-pink;
  }

  h2 {
    margin: 30px 0 20px;
    padding-bottom: 10px;
    font-size: clamp(32px, 5vw, 64px);

    @include breakpoint ('medium') {
      padding: 80px 0 40px;
      margin: 0;
    }

    div {
      border-bottom: 1px solid $color-blue;
      display: inline-block;
      padding-bottom: 10px;
    }
  }

  .swiper-container {
    overflow: visible;
    margin-top: 55px;
    padding-bottom: 20px;
    padding-left: 15px;
    padding-right: 15px;
    border: 1px solid $color-blue;

    @include breakpoint ('medium') {
      margin-top: 110px;
      padding-bottom: 110px;
    }

    .swiper-wrapper {
      @include breakpoint ('medium') {
        margin-left: 8%;
      }
    }

    .swiper-slide {
      height: auto;
      border: 1px solid $color-blue;

      table {
        height: 100%;
        min-width: 100%;

        tr {
          display: grid;

          td {
            padding: 10px 30px 40px;

            @include breakpoint ('medium') {
              padding-bottom: 80px;
            }
          }
        }

        ul {
          margin: 0;
        }
      }
    }
  }

  &__footer {
    display: flex;
    justify-content: center;
    margin-top: 20px;

    .swiper-button {
      padding: 0 12px;

      @include breakpoint-down('xsmall') {
        padding: 0;
      }
    }
  }
}
