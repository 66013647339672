header {
  height: 100vh;
  text-align: center;
  position: relative;
  min-height: 620px;

  #overlay {
    position: absolute;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba($color-black, 0.2);
    z-index: 2;
  }

  .swiper-slide {
    height: 100vh;
  }

  .hero {
    img {
      width: 100%;
      height: 100vh;
      object-fit: cover;
      z-index: -1;
    }
  }

  .event-tag {
    position: fixed;
    right: 0;
    top: 40%;
    height: 150px;
    width: auto;
    z-index: 10;

    @include breakpoint-down('small') {
      display: none;
    }

    @include breakpoint('xlarge') {
      height: 200px;
    }
  }

  .logo {
    position: absolute;
    top: 50px;
    z-index: 3;
    left: 50%;
    transform: translateX(-50%);
  }

  .container {
    display: grid;
    justify-items: center;
  }

  .menu-icon {
    padding-bottom: 16px;
  }

  h1 {
    margin-top: 10px;
    padding: 0 40px;
    color: $color-white;

    @include breakpoint ('large') {
      padding: 0;
      margin-top: 10px;
    }

    @include breakpoint ('xlarge') {
      margin-top: 30px;
    }

    @include breakpoint ('xlarge') {
      margin-top: 70px;
    }
  }

  .button {
    position: absolute;
    left: 50%;
    top: 80vh;
    transform: translate(-50%, -50%);

    svg {
      position: absolute;
      transform: translate(-50%, -50%);
    }

    &::before {
      border-radius: 44% 56% 38% 62% / 66% 61% 39% 34%;
      clip-path: none;
    }
  }

  .heading-animation {
    position: absolute;
    top: 150px;
    z-index: 3;
  }

  .swiper-pagination-bullet-active {
    background: $color-white;
  }

  .swiper-pagination-bullet {
    @include breakpoint('medium') {
      height: 12px;
      width: 12px;
    }
  }

  .swiper-container-horizontal > .swiper-pagination-bullets {
    left: 8px;
  }
}
