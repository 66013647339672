@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap');

@font-face {
  font-family: 'Saol Display';
  src: url('/fonts/SaolDisplay-Semibold.woff2') format('woff2');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

:root {
  --plex: 'Saol Display', sans-serif;
}

$open-sans: 'Open Sans', sans-serif;

.open-sans { font-family: $open-sans; }

body {
  font-family: $open-sans;
  font-size: 16px;
  line-height: 1.5;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--plex);
  font-weight: 400;
  margin-bottom: 24px;
  line-height: 1.2;
  text-align: center;
}

h1,
h2 {
  font-size: 40px;
  color: $color-blue;

  @include breakpoint('medium') {
    font-size: 68px;
    letter-spacing: 6.4px;
  }

  @include breakpoint('large') {
    font-size: 72px;
    letter-spacing: 8.4px;
  }

  @include breakpoint('xlarge') {
    font-size: 96px;
  }

  @include breakpoint('xxlarge') {
    font-size: 126px;
  }
}

h3 {
  font-size: 24px;

  @include breakpoint('medium') {
    font-size: 30px;
    letter-spacing: 4.5px;
  }
}

h4 {
  font-size: 20px;
  font-weight: 400;
}

p {
  text-align: left;
}

a {
  color: $color-white;
  text-decoration: underline;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.heading-animation {
  div {
    overflow: hidden;
  }

  span {
    position: relative;
    display: block;
  }
}
