$color-black: #000;
$color-white: #fff;
$color-blue: #0c3530;
$color-pink: #fadde1;
$color-beige: #fff8eb;
$color-red: #ff4f4f;

.background-pink {
  background-color: $color-pink;
  padding-top: 5px;
}

.background-beige {
  background-color: $color-beige;
}
