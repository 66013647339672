.event {
  color: $color-white;
  padding: 30px 0;
  z-index: 1;
  position: relative;

  @include breakpoint('medium') {
    padding: 80px 0;
  }

  @include breakpoint('large') {
    padding: 80px 0 160px;
  }

  .element-07 {
    position: absolute;
    right: -44%;
    bottom: 10%;
    z-index: -1;
    transform: scale(0.5);

    @include breakpoint('medium') {
      right: -35%;
      transform: scale(0.7);
      bottom: 15%;
    }

    @include breakpoint('large') {
      right: -350px;
    }

    @include breakpoint('xlarge') {
      right: 0;
    }
  }

  .background-blue {
    background-color: $color-blue;
    text-align: center;
    padding: 0 20px 35px;

    @include breakpoint('medium') {
      padding: 0 115px 75px 95px;
    }
  }

  h2 {
    font-weight: 400;
    font-size: clamp(32px, 5vw, 60px);
    padding: 30px 55px 10px;
    border-bottom: 1px solid $color-white;
    display: inline-block;
    color: $color-white;

    @include breakpoint('medium') {
      margin-top: 0;
      margin-bottom: 0;
      padding-top: 60px;
    }
  }

  h3 {
    @include breakpoint('medium') {
      padding-bottom: 35px;
    }
  }

  .offset-md-0 {
    @include breakpoint('medium') {
      grid-column-start: 1;
    }
  }
}
