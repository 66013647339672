.newsletter {
  background-image: url('/media/uploads/g-n_newsletter.jpg');
  background-color: $color-blue;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 80px 0;
  height: 100vh;
  width: 100vw;
  min-height: 620px;

  @include breakpoint('medium') {
    text-align: center;
  }

  .heading {
    background-color: $color-blue;
    color: $color-white;
    padding: 16px 30px;

    @include breakpoint('medium') {
      padding: 45px 0;
    }

    h3 {
      margin: 0;
    }
  }

  .content-container {
    color: $color-blue;
    background-color: $color-white;
    border: 1px solid $color-blue;
  }

  .content {
    letter-spacing: 1.2px;
    padding: 14px 20px 20px;
    text-align: center;

    @include breakpoint('medium') {
      padding: 25px 40px 0 50px;
    }

    p {
      padding-bottom: 10px;
    }

    input {
      width: 100%;
      padding: 10px 0 10px 30px;
      border: 1px solid $color-blue;
      margin-bottom: 30px;

      &::placeholder {
        padding: 10px 0 10px;
      }
    }

    .button {
      @include breakpoint('medium') {
        margin-bottom: 50px;
      }

      p {
        position: absolute;
        text-transform: uppercase;
        transform: translateY(-50%);
      }
    }
  }

  input:focus {
    outline: none;
  }

  .consent {
    background-color: $color-white;
    border-top: 1px solid $color-blue;
    display: flex;

    .checkbox {
      padding: 32px 16px;
      border-right: 1px solid $color-blue;

      @include breakpoint('medium') {
        padding: 16px 16px 12px;
      }

      input {
        -webkit-appearance: none;
        border: 8px solid $color-blue;
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0 -15px 10px -12px rgba(0, 0, 0, 0.05);
        padding: 4px;
        display: inline-block;
        position: relative;
        background-color: $color-white;
        cursor: pointer;

        @include breakpoint('medium') {
          border: 7px solid $color-blue;
          padding: 3px;
        }

        &:checked {
          padding: 4px;
          border: 8px solid $color-white;
          box-shadow: 0 0 1px 1px $color-blue;
          background-color: $color-blue;

          @include breakpoint('medium') {
            padding: 3px;
            border: 7px solid $color-white;
          }
        }
      }
    }

    label {
      font-size: 12px;
      padding: 25px;

      @include breakpoint('medium') {
        padding-left: 12px;
        padding-top: 15px;
      }
    }
  }

  .info {
    color: $color-blue;
    background-color: $color-white;
    padding: 40px 0;
    text-align: center;
  }
}
