.button {
  pointer-events: auto;
  cursor: pointer;
  background: $color-blue;
  color: $color-white;
  border: none;
  padding: 1.5rem 3rem;
  margin: 0;
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: 1.2px;
  position: relative;
  display: inline-block;
  z-index: 1;

  &::before,
  &::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &--janus {
    width: 95px;
    height: 110px;
    margin: 8px;
    background: none;
    padding: 0;
    z-index: 2;

    svg {
      width: 40px;
    }

    &::before {
      content: '';
      background: $color-blue;
      clip-path: path("M 35.2 2.8 C 29.5 3.4 21 12.9 18.6 16.1 C 15.9 19.6 13.3 23.3 11 27.1 C 3.6 39.6 -0.9 54.1 1.1 68.8 C 2.5 78.9 7.3 88.7 15.1 95.4 C 22.7 101.8 32.6 104.8 42.3 106.7 C 55.2 109.2 69.2 109.8 80.6 101.4 C 91.7 96.9 98.5 84.6 100.2 71.8 C 101.9 59.1 99 46.2 94.4 34.3 C 88.4 19 77.7 4.4 60.5 1.9 C 55.9 1.2 46 -1.2 35.2 2.7");
      transition: 0.5s ease;

      @include breakpoint-up('medium') {
        clip-path: path("M 54.2 4.3 c -8.8 1 -21.9 15.6 -25.6 20.4 c -4.2 5.4 -8.2 11.1 -11.7 17 c -11.4 19.2 -18.3 41.6 -15.2 64.1 c 2.2 15.6 9.5 30.7 21.5 40.9 c 11.7 9.9 26.9 14.6 41.9 17.5 c 19.8 3.8 41.4 4.7 58.9 -5.3 c 17 -9.8 27.5 -28.8 30.1 -48.4 c 2.6 -19.5 -1.8 -39.4 -8.9 -57.8 c -9.2 -23.4 -25.6 -46 -52.2 -49.8 c -7 -1 -22.3 -4.7 -38.8 1.3");
      }
    }

    &:hover::before {
      transform: rotate(18deg);

      @include breakpoint-down('medium') {
        transform: none;
      }
    }

    &::after {
      content: '';
      height: 106%;
      width: 106%;
      top: -3%;
      left: -3%;
      border-radius: 58% 42% 55% 45% / 56% 45% 55% 44%;
      border: 1px solid $color-blue;
      transform: rotate(-20deg);
      z-index: -1;
      transition: transform 0.5s cubic-bezier(0.585, 2.5, 0.645, 0.55);
    }

    &:hover::after {
      transform: translate3d(0, -5px, 0);

      @include breakpoint-down('medium') {
        transform: none;
      }
    }

    &:focus {
      outline: 0 !important;
      box-shadow: 0 0 0 0 rgba(0, 0, 0, 0) !important;
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }

    span {
      display: block;
      transition: transform 0.3s ease;
      z-index: 2;
      position: relative;
      text-transform: uppercase;
    }

    &:hover span {
      transform: translate3d(0, -10px, 0);

      @include breakpoint-down('medium') {
        transform: none;
      }
    }

    &.secondary {
      &::before {
        content: '';
        background: $color-blue;
        border: 1px solid $color-blue;
        border-radius: 44% 56% 38% 62% / 66% 61% 39% 34%;
        clip-path: none;
      }

      span {
        color: $color-white;
      }

      &:hover {
        &::before {
          background-color: transparent;
        }

        span {
          color: $color-blue;
        }

        svg {
          g {
            fill: $color-blue;
          }
        }
      }
    }

    &-white {
      &::before {
        background: $color-white;
      }

      &::after {
        border: 1px solid $color-white;
      }
    }
  }
}
