.cookies {
  position: fixed;
  width: 100%;
  bottom: 0;
  background-color: $color-white;
  padding: 12px 0;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
  z-index: 1000;

  @include breakpoint-up('medium') {
    padding: 24px 0;
  }
}
