.slider {
  margin-top: 15px;
  padding-bottom: 40px;
  overflow: visible;
  z-index: 1;
  position: relative;

  @include breakpoint('medium') {
    margin-top: 170px;
    padding-bottom: 160px;
  }

  .element-05 {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: -1;
    width: 100%;
  }

  .swiper-container {
    overflow: visible;
  }

  .offset-md-0 {
    @include breakpoint('medium') {
      grid-column-start: 1;
    }
  }

  &__footer {
    display: flex;
    justify-content: center;
    margin-top: 20px;

    @include breakpoint('medium') {
      margin-top: 85px;
    }

    .swiper-button {
      padding: 0 12px;

      @include breakpoint-down('xsmall') {
        padding: 0;
      }

      @include breakpoint('medium') {
        padding: 0 25px;
      }
    }
  }
}
