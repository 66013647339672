html,
body,
.body-overflow {
  min-height: 100vh;
  scroll-behavior: smooth;
}

.body-overflow {
  overflow-x: hidden;
  position: relative;
  width: 100%;
}

img {
  max-width: 100%;
}

body {
  background-color: $color-beige;

  &.no-scroll {
    overflow: hidden;
  }
}
