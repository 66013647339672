.reservations {
  margin-bottom: 30px;
  z-index: 1;
  position: relative;

  @include breakpoint('medium') {
    margin-bottom: 50px;
  }

  a {
    color: $color-blue;
  }

  .element-03 {
    position: absolute;
    transform: scale(0.7);
    z-index: -1;
    top: -15%;
    left: -70px;

    @include breakpoint('large') {
      top: -50%;
      left: -60px;
      transform: scale(0.8);
    }

    @include breakpoint('xlarge') {
      left: 0;
    }

    @include breakpoint('xxlarge') {
      left: 200px;
    }
  }

  form {
    border: 1px solid $color-blue;
    margin: 40px 0 20px;

    @include breakpoint('medium') {
      margin: 0;
    }
  }

  h3 {
    color: $color-blue;
    padding: 30px 55px;
    margin-bottom: 0;

    @include breakpoint('medium') {
      padding: 20px 0 30px;
    }
  }

  &__input {
    padding: 0 20px 20px;

    @include breakpoint ('medium') {
      display: grid;
      grid-template-columns: 1fr 1fr;
      padding: 0 40px 65px;
    }

    input,
    textarea {
      padding: 12px 20px;
      border: 1px solid $color-blue;
      width: 100%;
      margin-bottom: 16px;
      color: $color-blue;
      background-color: $color-beige;
    }

    &--left {
      @include breakpoint('medium') {
        margin-right: 16px;
      }
    }

    &--right {
      height: 340px;

      @include breakpoint ('medium') {
        margin-left: 16px;
        padding-bottom: 16px;
        height: auto;
      }

      textarea {
        height: 100%;
      }

      input:focus,
      textarea:focus {
        outline: none;
      }
    }
  }

  &__submit {
    background-color: $color-beige;
    text-align: center;

    @include breakpoint('medium') {
      padding-top: 70px;
    }
  }
}
